import { withValidation } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import {
  TPAGalleryNavigationButtonsSDK,
  TPAGalleryProps,
} from '../TPAGallery.types';

const _tpaGalleryNavigationButtonsSDKFactory: CorvidTypes.CorvidSDKFactory<
  TPAGalleryProps,
  TPAGalleryNavigationButtonsSDK
> = ({ props, setProps }) => {
  return {
    get showNavigationButtons() {
      return props.compProps.arrowMode;
    },

    set showNavigationButtons(val) {
      setProps({
        compProps: {
          ...props.compProps,
          arrowMode: val,
        },
      });
    },
  };
};

export const tpaGalleryNavigationButtonsSDKFactory = withValidation(
  _tpaGalleryNavigationButtonsSDKFactory,
  {
    type: ['object'],
    properties: {
      showNavigationButtons: { type: ['boolean'] },
    },
  },
);
