import {
  FileMetaData,
  FileType,
  UploadStatus,
} from '../../../components/FileUploader/FileUploader.types';
import { ValidationData } from './inputValidationTypes';
import {
  addErrorToValidationDataAndKeepMessage,
  getValidationMessage,
} from './validityUtils';

const fileTypeMatchers = {
  Image: /\.(bmp|gif|hei[cf]|jfif?|jpeg?|jpg|png|tiff?|webp)$/i,
  Document: /\.(ai|cdr|csv|doc[xb]?|dotx?|dwg|eps|e?pub|fla|gpx|ical(endar)?|ics|indd|ipynb|key|km[lz]|mobi|mt[fx]|numbers|od[gpst]|ot[pst]|oxps|pages|pd[fn]|pkg|potx?|pp[st]x?|psd|rtf|sldx|txt|vcf|xcf|xl[st]x?|xlw|xps)$/i,
  Video: /\.(3gp|avi|divx|flv|m1v|m2ts|m4v|mkv|mov|mp4|mpeg?|mpg|mxf|ogv|vob|webm|wmv|xvid)$/i,
  Audio: /\.(aac|aiff?|flac|m4a|mp3|wav|wma)$/i,
} as const;
const fileSizes = {
  Image: 25000000,
  Document: 1000000000,
  Video: 1000000000,
  Audio: 50000000,
} as const;

const KB = 1000;
const MB = KB * 1000;
const GB = MB * 1000;

const isFileTypeValid = (fileType: FileType, fileName: string) =>
  fileTypeMatchers[fileType].test(fileName);
const isFileSizeValid = (fileType: FileType, fileSize: number) =>
  fileSize <= fileSizes[fileType];
const getFileExtension = (fileName: string): string => {
  const match = /\.(\w+)$/.exec(fileName);
  return ((match && match[1]) || '').toUpperCase();
};
const getMaxFileSize = (fileType: FileType): string => {
  const bytes = fileSizes[fileType];
  if (bytes > 999999999) {
    return `${bytes / GB} GB`;
  }
  if (bytes > 999999) {
    return `${bytes / MB} MB`;
  }
  return `${bytes / KB} KB`;
};

export const validateFile = (
  props: {
    value: Array<FileMetaData>;
    uploadStatus: UploadStatus;
    fileType: FileType;
  },
  validationData: ValidationData,
): ValidationData => {
  const { value, uploadStatus, fileType } = props;
  const fileData = value[0];
  const uploadNotStarted = uploadStatus === 'Not_Started';

  if (fileData && uploadNotStarted) {
    const fileName = fileData.name || '';
    if (!isFileTypeValid(fileType, fileName)) {
      validationData = addErrorToValidationDataAndKeepMessage(
        validationData,
        'fileTypeNotAllowed',
        getValidationMessage('fileTypeNotAllowed', getFileExtension(fileName)),
      );
    }
    if (!isFileSizeValid(fileType, fileData.size || 0)) {
      validationData = addErrorToValidationDataAndKeepMessage(
        validationData,
        'fileSizeExceedsLimit',
        getValidationMessage('fileSizeExceedsLimit', getMaxFileSize(fileType)),
      );
    }
  }
  return validationData;
};
