import { InternalAddress } from '../../../providers/googleMaps/types';
import { ValidationData } from './inputValidationTypes';
import { addErrorToValidationDataAndKeepMessage } from './validityUtils';

export const validateRequiredAddress = (
  props: {
    required: boolean;
    value?: InternalAddress;
  },
  validationData: ValidationData,
): ValidationData => {
  const isRequired = props.required || false;

  if (!isRequired || (props.value && props.value.formatted)) {
    return validationData;
  }

  return addErrorToValidationDataAndKeepMessage(validationData, 'valueMissing');
};
