export const ColumnType = {
  String: 'string',
  Number: 'number',
  Date: 'date',
  Image: 'image',
  Bool: 'bool',
  RichText: 'richText',
} as const;

export const DataSource = {
  Static: 'static',
  Dynamic: 'dynamic',
} as const;

export const PaginationType = {
  None: 'normal',
  Pages: 'pagination',
  Scroll: 'virtual',
} as const;

export const UserSelectionType = {
  None: 'none',
  Row: 'row',
  Cell: 'cell',
} as const;

export const ColumnLayout = {
  Manual: 'manual',
  Equal: 'equal',
} as const;

export const LinkTarget = {
  Blank: '_blank',
  Self: '_self',
} as const;

export const HeaderColumn = {
  None: 'none',
  First: 'first',
  Last: 'last',
} as const;

export const TestId = {
  Pagination: 'pagination',
  PaginationText: 'pagination-text',
  PagePrev: 'page-prev',
  PageNext: 'page-next',
  Loader: 'loader',
} as const;

export const DEFAULT_COLUMN_WIDTH = 200;
export const DEFAULT_ROWS_PER_PAGE = 100;
