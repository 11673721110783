import {
  CustomValidationRules,
  ValidationSchema,
  assert,
  CustomPropertyValidator,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  ISelectionTagsListProps,
  ISelectionTagsListSDKBase,
} from '../SelectionTagsList.types';

export const validationSchema: ValidationSchema = {
  type: ['object'],
  properties: {
    value: {
      type: ['array', 'nil'],
      items: {
        type: ['string'],
      },
    },
    selectedIndices: {
      type: ['array', 'nil'],
      items: {
        type: ['integer'],
      },
    },
    options: {
      type: ['array'],
      name: 'SelectionTags.Option',
      items: {
        type: ['object'],
        properties: {
          value: {
            type: ['string', 'nil'],
          },
          label: {
            type: ['string', 'nil'],
          },
          link: {
            type: ['string', 'nil'],
          },
          rel: {
            type: ['string', 'nil'],
          },
        },
      },
    },
  },
};

const isInRange: CustomPropertyValidator<
  ISelectionTagsListProps,
  ISelectionTagsListSDKBase
> = (indices: Array<unknown> | null | undefined, { props, metaData }) => {
  const schemaValidator = createCompSchemaValidator(metaData.compId);

  if (assert.isNil(indices)) {
    return true;
  }

  return schemaValidator(
    indices,
    {
      type: ['array'],
      items: {
        type: ['integer'],
        minimum: 0,
        maximum: props.options.length - 1,
      },
    },
    'selectedIndices',
  );
};

export const customValidators: CustomValidationRules<
  ISelectionTagsListProps,
  ISelectionTagsListSDKBase
> = {
  selectedIndices: [isInRange],
};
