import {
  CustomValidator,
  ValidationData,
  TViewerSdkAPI,
} from './inputValidationTypes';
import { INITIAL_VALIDATION_DATA } from './validityUtils';

interface IValidationPropSDKState {
  validationData: ValidationData;
  customValidator: CustomValidator;
}

const defaultState: IValidationPropSDKState = {
  validationData: INITIAL_VALIDATION_DATA,
  customValidator: () => {},
};

const NAMESPACE = 'validation';

export const getValidationData = ({
  createSdkState,
}: TViewerSdkAPI): ValidationData => {
  const [state] = createSdkState(defaultState, NAMESPACE);
  return state.validationData;
};

export const setValidationData = (
  { createSdkState }: TViewerSdkAPI,
  validationData: ValidationData,
) => {
  const [, setState] = createSdkState(defaultState, NAMESPACE);
  setState({ validationData });
};

export const getCustomValidator = ({
  createSdkState,
}: TViewerSdkAPI): CustomValidator => {
  const [state] = createSdkState(defaultState, NAMESPACE);
  return state.customValidator;
};

export const setCustomValidator = (
  { createSdkState }: TViewerSdkAPI,
  customValidator: CustomValidator,
) => {
  const [, setState] = createSdkState(defaultState, NAMESPACE);
  setState({ customValidator });
};
