export default {
  ldquo: '\u201C',
  lsquo: '\u2018',
  rsquo: '\u2019',
  laquo: '\u00AB',
  raquo: '\u00BB',
  lsaquo: '\u2039',
  rsaquo: '\u203A',
  lt: '<',
  gt: '>',
  amp: '&',
  bull: '\u2022',
  deg: '\u00B0',
  hellip: '\u2026',
  trade: '\u2122',
  copy: '\u00A9',
  reg: '\u00AE',
  mdash: '\u2014',
  ndash: '\u2013',
  nbsp: '\u00A0',
  emsp: '\u2003',
  ensp: '\u2002',
  sup2: '\u00B2',
  sup3: '\u00B3',
  frac14: '\u00BC',
  frac12: '\u00BD',
  frac34: '\u00BE',
};
