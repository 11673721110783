import {
  composeSDKFactories,
  createCompSchemaValidator,
  assert,
  Schema,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IToggleSwitchProps,
  IToggleSwitchSDK,
  IToggleSwitchImperativeActions,
  IToggleSwitchOwnSDKFactory,
} from '../ToggleSwitch.types';
import {
  createElementPropsSDKFactory,
  createValuePropsSdkFactory,
  createCheckedPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

import { createEmptyInputValidator } from '../../../core/corvid/inputUtils';

const valuePropsSDKFactory = createValuePropsSdkFactory<
  IToggleSwitchProps,
  IToggleSwitchImperativeActions
>(
  /**
   * The original bolt implementation have this logic
   * 1. If null value - report warning and return null
   * 2. If undefined value - report warning and return previous value
   * Because of this functionality we need to do custom schema validation here
   * */
  (value, api) => {
    const schemaValidator = createCompSchemaValidator(api.metaData.role);
    const valueSchema = { type: ['string', 'nil'], warnIfNil: true } as Schema;
    schemaValidator(value, valueSchema, 'value');
    /**
     * fallback to previous value if value is
     * 1. undefined
     * 2. any other wrong value that is not string
     */
    if (!assert.isString(value) && value !== null) {
      return api.props.value;
    }
    return value;
  },
  // this schema will always go green, because we already do validation in function above
  { type: ['string', 'nil'] },
  createEmptyInputValidator(),
);

const checkedPropsSDKFactory = createCheckedPropsSDKFactory<
  IToggleSwitchProps,
  IToggleSwitchImperativeActions
>(createEmptyInputValidator());

const ownSDKFactory: IToggleSwitchOwnSDKFactory = ({ props, metaData }) => {
  return {
    get type() {
      return '$w.Switch';
    },
    toJSON() {
      const { checked, value } = props;
      return {
        ...toJSONBase(metaData),
        checked,
        value,
        type: '$w.Switch',
      };
    },
  };
};
const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IToggleSwitchProps, IToggleSwitchSDK>(
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  clickPropsSDKFactory,
  checkedPropsSDKFactory,
  valuePropsSDKFactory,
  ownSDKFactory,
);

export default createComponentSDKModel(sdk);
