import {
  assert,
  withValidation,
  composeSDKFactories,
  reportError,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IAddressInputProps,
  IAddressInputSDK,
  IAddressInputImperativeActions,
  IAddressInputOwnSDKFactory,
} from '../AddressInput.types';
import {
  changePropsSDKFactory,
  clickPropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  disablePropsSDKFactory,
  createElementPropsSDKFactory,
  focusPropsSDKFactory,
  toJSONBase,
  readOnlyPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  composeValidators,
  createInputValidator,
  validateRequiredAddress,
  InputValidator,
} from '../../../core/corvid/inputUtils';

const addressInputValidator: InputValidator<
  IAddressInputProps,
  IAddressInputImperativeActions
> = createInputValidator(
  composeValidators<IAddressInputProps>([validateRequiredAddress]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  addressInputValidator,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  addressInputValidator,
);

const isCountry = (value: string) => Boolean(/^[a-zA-Z]{3}$/.exec(value));

const _ownSDKFactory: IAddressInputOwnSDKFactory = api => {
  const { props, setProps, metaData } = api;
  return {
    get type() {
      return '$w.AddressInput';
    },
    get value() {
      const { value } = props;
      if (value) {
        return {
          formatted: value.formatted,
          country: value.country,
          location: value.location,
          streetAddress: value.streetAddress,
          postalCode: value.postalCode,
          subdivision: value.subdivision,
          city: value.city,
        };
      }
      return value;
    },
    set value(value) {
      if (assert.isNil(value) || assert.isString(value.formatted)) {
        setProps({ value });
      }
      addressInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get filter() {
      return {
        country: props.country,
      };
    },
    set filter(filter) {
      if (assert.isNil(filter)) {
        // null and undefined will be handled gracefully, as an empty object.
        setProps({ country: undefined });
      } else if (filter.country && isCountry(filter.country)) {
        setProps({ country: filter.country });
      }
    },
    get placeholder() {
      return props.placeholder;
    },
    set placeholder(placeholder: string | number | undefined) {
      if (assert.isNil(placeholder)) {
        setProps({ placeholder: '' });
      } else if (assert.isNumber(placeholder)) {
        setProps({ placeholder: placeholder.toString() });
      } else {
        setProps({ placeholder });
      }
    },
    toJSON() {
      const { value, required } = props;
      return {
        ...toJSONBase(metaData),
        required,
        value,
      };
    },
  };
};

const ownSDKFactory = withValidation(
  _ownSDKFactory,
  {
    type: ['object'],
    properties: {
      value: {
        type: ['object', 'nil'],
        properties: {
          formatted: {
            type: ['string'],
          },
        },
      },
      filter: {
        type: ['object', 'nil'],
        properties: {
          country: {
            type: ['string', 'nil'],
          },
        },
      },
      placeholder: {
        type: ['string', 'number', 'nil'],
      },
    },
  },
  {
    filter: [
      (value, { metaData }) => {
        let isValid = true;
        // null and undefined will be handled gracefully, as an empty object.
        if (!assert.isNil(value)) {
          if (value.country) {
            if (!isCountry(value.country)) {
              reportError(
                `The "filter" property of "${metaData.role}" was set to the following invalid value: "${value}". The country code is not a valid ISO 3166-1 Alpha-2. For more information, please visit https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2.`,
              );
              isValid = false;
            }
          } else if (assert.isObject(value)) {
            reportError(
              `The "filter" property of "${metaData.role}" was set to the following invalid value: "${value}". The only filter supported at the moment is country code, for example: {country: ‘US’}.`,
            );
            isValid = false;
          } else {
            reportError(
              `The "filter" property of "${metaData.role}" was set to the following invalid value: "${value}". The value must be a valid filter object, for example: {country: ‘US’}.`,
            );
            isValid = false;
          }
        }
        return isValid;
      },
    ],
    value: [
      (value, { metaData }) => {
        let isValid = true;
        if (!assert.isNil(value)) {
          if (!assert.isString(value.formatted)) {
            reportError(
              `The "value.formatted" property of "${metaData.role}" was set to the following invalid value: ${value.formatted}. The value must be a valid Address object.`,
            );
            isValid = false;
          }
        }
        return isValid;
      },
    ],
  },
);

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IAddressInputProps, IAddressInputSDK>(
  elementPropsSDKFactory,
  requiredPropsSDKFactory,
  readOnlyPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  validationPropsSDKFactory,
  ownSDKFactory,
);

export default createComponentSDKModel(sdk);
