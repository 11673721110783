import {
  withValidation,
  composeSDKFactories,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  changePropsSDKFactory,
  clickPropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  disablePropsSDKFactory,
  createElementPropsSDKFactory,
  focusPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IRatingsInputProps,
  IRatingsInputImperativeActions,
  IRatingsInputSDK,
  IRatingsInputOwnSDKFactory,
  IIconMouseInComponentPayload,
  IIconMouseInCorvidEvent,
} from '../RatingsInput.types';
import {
  composeValidators,
  createInputValidator,
  InputValidator,
  validateRequired,
} from '../../../core/corvid/inputUtils';

const ratingsInputValidator: InputValidator<
  IRatingsInputProps,
  IRatingsInputImperativeActions
> = createInputValidator(
  composeValidators<IRatingsInputProps>([validateRequired]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  ratingsInputValidator,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  ratingsInputValidator,
);

const _ratingsInputPropsSKDFactory: IRatingsInputOwnSDKFactory = api => {
  const { setProps, props } = api;
  return {
    get value() {
      return props.value;
    },
    get type() {
      return '$w.RatingsInput';
    },
    set value(value) {
      setProps({ value: value || null });

      ratingsInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    onIconMouseIn: handler =>
      registerCorvidEvent<
        IIconMouseInComponentPayload,
        IIconMouseInCorvidEvent
      >('iconMouseIn', api, handler, ({ componentEvent }) => componentEvent),
  };
};

const ratingsInputPropsSKDFactory = withValidation(
  _ratingsInputPropsSKDFactory,
  {
    type: ['object'],
    properties: {
      value: {
        type: ['integer', 'nil'],
        maximum: 5,
        minimum: 1,
      },
    },
  },
);

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IRatingsInputProps, IRatingsInputSDK>(
  elementPropsSDKFactory,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  ratingsInputPropsSKDFactory,
);

export default createComponentSDKModel(sdk);
