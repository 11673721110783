import {
  withValidation,
  composeSDKFactories,
  messageTemplates,
  reportWarning,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  changePropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
  createValuePropsSdkFactory,
  clickPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  ISliderProps,
  ISliderSDK,
  ISliderOwnSDKFactory,
  ISliderImperativeActions,
} from '../Slider.types';
import { createEmptyInputValidator } from '../../../core/corvid/inputUtils';

const _sliderSDKFactory: ISliderOwnSDKFactory = ({
  setProps,
  props,
  metaData,
}) => {
  const sdkProps = {
    get min() {
      return props.min;
    },
    set min(value) {
      if (value > props.max) {
        reportWarning(
          messageTemplates.warning_at_most({
            propertyName: 'min',
            wrongValue: value,
            maxValue: props.max,
          }),
        );
      }
      setProps({ min: value });
    },
    get max() {
      return props.max;
    },
    set max(value) {
      if (value < props.min) {
        reportWarning(
          messageTemplates.warning_at_least({
            propertyName: 'max',
            wrongValue: value,
            minValue: props.min,
          }),
        );
      }
      setProps({ max: value });
    },
    get step() {
      return props.step || 0.1;
    },
    set step(value) {
      setProps({ step: value });
    },
    get stepType() {
      return props.stepType;
    },
    set stepType(value) {
      setProps({ stepType: value });
    },
    toJSON() {
      const { min, max, stepType, step } = sdkProps;
      return {
        ...toJSONBase(metaData),
        min,
        max,
        stepType,
        step,
        value: props.value,
      };
    },
  };
  return sdkProps;
};

const sliderSDKFactory = withValidation(_sliderSDKFactory, {
  type: ['object'],
  properties: {
    min: { type: ['number'] },
    max: { type: ['number'] },
    step: { type: ['number', 'nil'] },
    stepType: { type: ['string'], enum: ['value', 'count'] },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();
const valuePropsSDKFactory = createValuePropsSdkFactory<
  ISliderProps,
  ISliderImperativeActions
>(value => value, { type: ['number'] }, createEmptyInputValidator());

export const sdk = composeSDKFactories<ISliderProps, ISliderSDK>(
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
  valuePropsSDKFactory,
  clickPropsSDKFactory,
  sliderSDKFactory,
);

export default createComponentSDKModel(sdk);
