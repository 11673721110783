import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { IVideoLegacySDKFactory } from '../VideoLegacy.types';
import { createElementPropsSDKFactory } from '../../../core/corvid/props-factories/elementPropsSDKFactory';
import {
  getVideoUrl,
  getVideoDataFromUrl,
  isValidUrl,
} from './videoUtils/utils';

const customRules = {
  videoUrl: [isValidUrl],
};

const _videoPlayerSDKFactory: IVideoLegacySDKFactory = ({
  setProps,
  props,
}) => ({
  get videoUrl() {
    const { videoType, videoId } = props;

    return getVideoUrl({ videoType, videoId });
  },

  set videoUrl(url: string) {
    const { videoId, videoType } = getVideoDataFromUrl(url);

    if (videoId && videoType) {
      setProps({
        videoId,
        videoType,
      });
    }
  },
});

const videoPlayerSDKFactory: IVideoLegacySDKFactory = withValidation(
  _videoPlayerSDKFactory,
  {
    type: ['object'],
    properties: {
      videoUrl: { type: ['string'] },
    },
  },
  customRules,
);

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk: IVideoLegacySDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  videoPlayerSDKFactory,
);

export default createComponentSDKModel(sdk);
