import {
  withValidation,
  composeSDKFactories,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IQuickActionBarSDKFactory,
  IQuickActionBarProps,
  IQuickActionBarSDK,
  OnItemClickedComponentEvent,
  OnItemClickedCorvidEvent,
} from '../QuickActionBar.types';
import { createElementPropsSDKFactory } from '../../../core/corvid/props-factories';

const _quickActionBarSDKFactory: IQuickActionBarSDKFactory = api => {
  const { setProps, props } = api;
  return {
    get alignment() {
      return props.alignment;
    },
    set alignment(value) {
      setProps({ alignment: value });
    },
    get colorScheme() {
      return props.colorScheme;
    },
    set colorScheme(value) {
      setProps({ colorScheme: value });
    },
    get invertColorScheme() {
      return props.isColorSchemeInverted;
    },
    set invertColorScheme(shouldInvert) {
      setProps({ isColorSchemeInverted: shouldInvert });
    },
    get showLabels() {
      return !props.hideText;
    },
    set showLabels(shouldShow) {
      setProps({ hideText: !shouldShow });
    },
    onItemClicked: cb =>
      registerCorvidEvent<
        OnItemClickedComponentEvent,
        OnItemClickedCorvidEvent
      >(
        'onQuickActionBarItemClicked',
        api,
        cb,
        ({ componentEvent }) => componentEvent,
      ),
  };
};

const quickActionBarSDKFactory = withValidation(_quickActionBarSDKFactory, {
  type: ['object'],
  properties: {
    showLabels: {
      type: ['boolean'],
    },
    alignment: {
      type: ['string'],
      enum: ['left', 'right'],
    },
    colorScheme: {
      type: ['string'],
      enum: ['brand', 'grey', 'black'],
    },
    invertColorScheme: {
      type: ['boolean'],
    },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  IQuickActionBarProps,
  any,
  IQuickActionBarSDK
>(elementPropsSDKFactory, quickActionBarSDKFactory);

export default createComponentSDKModel(sdk);
