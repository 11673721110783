import {
  composeSDKFactories,
  createCompSchemaValidator,
  assert,
  reportWarning,
  messageTemplates,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  createElementPropsSDKFactory,
  clickPropsSDKFactory,
  disablePropsSDKFactory,
  toJSONBase,
  changePropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IPaginationProps,
  IPaginationSDK,
  IPaginationOwnSDKFactory,
} from '../Pagination.types';

const paginationSDKFactory: IPaginationOwnSDKFactory = ({
  setProps,
  props,
  metaData,
}) => {
  const schemaValidator = createCompSchemaValidator(metaData.role);

  return {
    get currentPage() {
      return props.currentPage;
    },

    set currentPage(newCurrentPage) {
      if (
        !schemaValidator(
          newCurrentPage,
          {
            type: ['integer', 'nil'],
            minimum: 1,
            maximum: props.totalPages,
            warnIfNil: true,
          },
          'currentPage',
        )
      ) {
        return;
      }
      setProps({
        currentPage: assert.isNil(newCurrentPage) ? 1 : newCurrentPage,
      });
    },

    get totalPages() {
      return props.totalPages;
    },

    set totalPages(newTotalPages) {
      if (
        !schemaValidator(
          newTotalPages,
          { type: ['integer'], minimum: 1 },
          'totalPages',
        )
      ) {
        return;
      }
      if (props.currentPage > newTotalPages) {
        reportWarning(
          messageTemplates.warning_value_changed({
            propertyName: 'totalPages',
            compName: metaData.role,
            newValue: String(newTotalPages),
            changedProperty: 'currentPage',
          }),
        );
        setProps({ totalPages: newTotalPages, currentPage: newTotalPages });
      } else {
        setProps({ totalPages: newTotalPages });
      }
    },

    toJSON() {
      const { currentPage, totalPages } = props;
      return {
        ...toJSONBase(metaData),
        currentPage,
        totalPages,
      };
    },
  };
};

export const sdk = composeSDKFactories<IPaginationProps, IPaginationSDK>(
  createElementPropsSDKFactory(),
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  changePropsSDKFactory,
  paginationSDKFactory,
);

export default createComponentSDKModel(sdk);
