export default {
  '0': '\uFFFD',
  '128': '\u20AC',
  '130': '\u201A',
  '131': '\u0192',
  '132': '\u201E',
  '133': '\u2026',
  '134': '\u2020',
  '135': '\u2021',
  '136': '\u02C6',
  '137': '\u2030',
  '138': '\u0160',
  '139': '\u2039',
  '140': '\u0152',
  '142': '\u017D',
  '145': '\u2018',
  '146': '\u2019',
  '147': '\u201C',
  '148': '\u201D',
  '149': '\u2022',
  '150': '\u2013',
  '151': '\u2014',
  '152': '\u02DC',
  '153': '\u2122',
  '154': '\u0161',
  '155': '\u203A',
  '156': '\u0153',
  '158': '\u017E',
  '159': '\u0178',
};
