import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { createElementPropsSDKFactory } from '../../../../core/corvid/props-factories';
import {
  ICustomElementComponentSDKFactory,
  ICustomElementComponentProps,
  ICustomElementComponentSDK,
  CustomEventHandler,
} from '../CustomElementComponent.types';

const _customElementComponent: ICustomElementComponentSDKFactory = ({
  setProps,
  props,
  registerEvent,
  createSdkState,
}) => {
  const [state, setState] = createSdkState<
    Record<string, ReadonlyArray<CustomEventHandler>>
  >({});

  registerEvent<CustomEvent>('handleEvents', event => {
    if (state[event.type]) {
      state[event.type].forEach(customEventHandler =>
        customEventHandler(event),
      );
    }
  });
  return {
    get seoMarkup() {
      return props.seoMarkup;
    },
    set seoMarkup(value) {
      setProps({ seoMarkup: value });
    },
    setAttribute(key: string, value: string | boolean | number) {
      setProps({ attributes: { ...props.attributes, [key]: value } });
    },
    on(eventName: string, callBackFunction: CustomEventHandler) {
      if (state[eventName]) {
        setState({
          [eventName]: [...state[eventName], callBackFunction],
        });
      } else {
        setState({
          [eventName]: [callBackFunction],
        });
      }
      setProps({ events: Object.keys(state) });
    },
  };
};

const customElementComponent = withValidation(_customElementComponent, {
  type: ['object'],
  properties: {
    seoMarkup: { type: ['string'] },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  ICustomElementComponentProps,
  ICustomElementComponentSDK
>(elementPropsSDKFactory, customElementComponent);

export default createComponentSDKModel(sdk);
