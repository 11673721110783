import { ValidationData } from './inputValidationTypes';
import { addErrorToValidationDataAndKeepMessage } from './validityUtils';

export const validateRequiredArray = (
  props: {
    required: boolean;
    value: Array<any>;
  },
  validationData: ValidationData,
): ValidationData => {
  const isRequired = props.required || false;

  if (!isRequired || props.value.length) {
    return validationData;
  }

  return addErrorToValidationDataAndKeepMessage(validationData, 'valueMissing');
};
