import {
  withValidation,
  composeSDKFactories,
  createCompSchemaValidator,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';

import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IHtmlComponentSDKFactory,
  IHtmlComponentProps,
  IHtmlComponentSDK,
  OnMessageCorvidEvent,
  OnMessageComponentEvent,
} from '../HtmlComponent.types';
import { createElementPropsSDKFactory } from '../../../core/corvid/props-factories';

export const _htmlComponentSdkFactory: IHtmlComponentSDKFactory = api => {
  const { setProps, props, compRef, metaData, getSdkInstance } = api;
  const messageValidator = (value: any, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['string', 'function', 'number', 'boolean', 'array', 'object'],
      },
      setterName,
    );

  return {
    get scrolling() {
      return props.scrolling;
    },

    set scrolling(value) {
      setProps({ scrolling: value });
    },

    get src() {
      return props.url;
    },
    set src(value) {
      setProps({ url: value });
    },

    allowFullScreen: () => {
      setProps({ allow: 'fullscreen' });
      return getSdkInstance();
    },

    onMessage: cb =>
      registerCorvidEvent<OnMessageComponentEvent, OnMessageCorvidEvent>(
        'onMessage',
        api,
        cb,
        ({ componentEvent }) => componentEvent,
      ),

    postMessage: message => {
      if (!messageValidator(message, 'postMessage')) {
        return getSdkInstance();
      }
      compRef.postMessage(message);
      return getSdkInstance();
    },
  };
};

const htmlComponentSdkFactory = withValidation(_htmlComponentSdkFactory, {
  type: ['object'],
  properties: {
    src: { type: ['string'] },
    scrolling: { type: ['string'], enum: ['yes', 'no', 'auto'] },
    allowFullScreen: { type: ['function'] },
  },
});
const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IHtmlComponentProps, IHtmlComponentSDK>(
  elementPropsSDKFactory,
  htmlComponentSdkFactory,
);

export default createComponentSDKModel(sdk);
