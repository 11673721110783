import {
  composeSDKFactories,
  withValidation,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  ICaptchaProps,
  ICaptchaSDK,
  ICaptchaOwnSDKFactory,
} from '../Captcha.types';
import {
  focusPropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const _ownSDKFactory: ICaptchaOwnSDKFactory = api => {
  const { props, compRef, metaData } = api;
  return {
    blur() {},
    get token() {
      return props.token;
    },
    reset() {
      compRef.reset();
    },
    onError: handler => registerCorvidEvent('onError', api, handler),
    onTimeout: handler => registerCorvidEvent('onTimeout', api, handler),
    onVerified: handler => registerCorvidEvent('onVerified', api, handler),
    toJSON() {
      const { token } = props;
      return {
        ...toJSONBase(metaData),
        token,
      };
    },
  };
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {},
});

const elementPropsSDKFactory = createElementPropsSDKFactory();
export const sdk = composeSDKFactories<ICaptchaProps, ICaptchaSDK>(
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  ownSDKFactory,
);

export default createComponentSDKModel(sdk);
